<template>
  <CRow>
    <CCol col="12" xl="12">
      <CCard>
        <CCardHeader> 予約詳細 </CCardHeader>
        <CRow class="statuses">
          <CCol style="font-weight: bold" sm="2"> ステータス </CCol>
          <CCol sm="2">
            {{this.status}}
          </CCol>
        </CRow>
        <CRow class="statuses">
          <CCol style="font-weight: bold" sm="2"> 予約日時 </CCol>
          <CCol sm="2">
            {{this.scheduledTime}}
          </CCol>
        </CRow>
        <CRow class="statuses">
          <CCol style="font-weight: bold" sm="2"> メッセージ </CCol>
          <CCol sm="2">
            {{this.message || "メッセージなし" }}
          </CCol>
        </CRow>
        <CCardBody>
          <div v-if="loading">
            <LoadingCompoment />
          </div>
          <div v-else :key="key">
            <CDataTable
              hover
              striped
              :items="tableData"
              :fields="fields"
              :items-per-page-select="{
                  label: '表示件数',
                  values: [10, 20, 50, 100, 200, 500],
              }"
              :items-per-page="200"
              pagination
            >
              <td slot="id" slot-scope="{item}">
                <CButton class="btn-error" variant='ghost'>
                    {{item.id}}
                </CButton>
              </td>
              <td slot="item_id" slot-scope="{item}">
                <CButton class="btn-error" variant='ghost'>
                  {{item.item_id}}
                </CButton>
              </td>
              <td slot="arrived_at" slot-scope="{item}">
                <CButton class="btn-error" variant='ghost'>
                  {{ item.arrived_at }}
                </CButton>
              </td>
              <td slot="shop" slot-scope="{item}">
                <CButton class="btn-error" variant='ghost'>
                  {{item.shop || ''}}
                </CButton>
              </td>
              <td slot="item_master_id" slot-scope="{item}">
                <CButton class="btn-error" variant='ghost'>
                  {{item.item_master_id || ''}}
                </CButton>
              </td>
              <td slot="shelf_id" slot-scope="{item}">
                <CButton class="btn-error" variant='ghost'>
                  {{item.shelf_id || ''}}
                </CButton>
              </td>
              <td slot="item_masters_size" slot-scope="{item}">
                <CButton class="btn-error" variant='ghost'>
                    {{item.item_masters_size || ''}}
                </CButton>
              </td>
              <td slot="item_masters_color" slot-scope="{item}">
                <CButton class="btn-error" variant='ghost'>
                    {{item.item_masters_color || ''}}
                </CButton>
              </td>
              <td slot="item_code" slot-scope="{item}">
                <CButton class="btn-error" variant='ghost'>
                    {{item.item_code || ''}}
                </CButton>
              </td>
              <td slot="item_masters_name" slot-scope="{item}">
                <CButton class="btn-error" variant='ghost'>
                    {{item.item_masters_name || ''}}
                </CButton>
              </td>
              <td slot="item_masters_cost" slot-scope="{item}">
                <CButton class="btn-error" variant='ghost'>
                    {{item.item_masters_cost_commas || ''}}
                </CButton>
              </td>
              <td slot="item_price" slot-scope="{item}">
                <CButton class="btn-error" variant='ghost'>
                    {{item.item_price_commas || ''}}
                </CButton>
              </td>
              <td slot="item_masters_retail_price" slot-scope="{item}">
                <CButton class="btn-error" variant='ghost'>
                    {{item.item_masters_retail_price_commas || ''}}
                </CButton>
              </td>
              <td slot="pp_bs_code" slot-scope="{item}">
                <CButton class="btn-error" variant='ghost'>
                    {{item.pp_bs_code || ''}}
                </CButton>
              </td>
              <td slot="is_special" slot-scope="{item}">
                <CButton class="btn-error" variant='ghost'>
                    {{item.is_special || ''}}
                </CButton>
              </td>
              <td slot="status" slot-scope="{item}">
                <CButton class="btn-error" variant='ghost'>
                    {{item.status || ''}}
                </CButton>
              </td>
            </CDataTable>
          </div>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>  
</template>
<script>
import Vue from "vue"
import ResourceApi from "@/api/resourceApi"
import apiStockOther from '../../api/stockApi';
import apiImportScheduleOther from '../../api/importScheduleApi';
import {numberWithCommas} from "@/utils/utils";

const scheduleApi = new ResourceApi("import-schedules")

export default {
  name: 'ScheduleDetail',
  data () {
    return {
      scheduleId: this.$route.params.id,
      loading: false,
      tableData: [],
      errors: {},
      tableHeader: [],
      headerArray: [],
      key: Math.random(),
      fields: [
        { key: 'id', label: 'ID' },
        { key: 'item_id', label: '商品ID' },
        { key: 'arrived_at', label: '*入荷日' },
        { key: 'shop', label: '店舗' },
        { key: 'item_master_id', label: '*商品マスタID' },
        { key: 'shelf_id', label: '*棚ID' },
        { key: 'item_code', label: '品番コード'},
        { key: 'item_masters_name', label: '品名' },
        { key: 'item_masters_size', label: '製品サイズ名' },
        { key: 'item_masters_color', label: 'カラー名' },
        { key: 'item_masters_cost', label: '仕入原価' },
        { key: 'item_price', label: '*販売価格' },
        { key: 'item_masters_retail_price', label: 'プロパー上代' },
        { key: 'pp_bs_code', label: '*PP/BS' },
        { key: 'is_special', label: '*優待' },
        { key: 'status', label: '状態' },
      ],
      results: null,
      status:'',
      scheduledTime:'',
      messsage:'',
    }
  },
  created() {
    this.getSchedule()
  },
  methods: {
    getSchedule() {
      this.loading = true

      if (!this.scheduleId) {
        this.loading = false
        return
      }

      apiImportScheduleOther.downloadCsv({ scheduleId: this.scheduleId })
        .then((response) => {
          let results = []
          results = this.convertStrToJson(response.data.csvString)
          this.generateData(results)

          this.status = response.data.status === 0 ? "未処理" : (response.data.status === 1 ? "正常終了" : "異常終了")
          this.scheduledTime = response.data.scheduled_at
          this.message = response.data.message

          this.loading = false
        }).catch(() => {
          this.loading = false
        })

    },
    convertStrToJson(csvText) {
      // Split all the text into seperate lines on new lines and carriage return feeds
      var allTextLines = csvText.split(/\r\n|\n/)

      var locations = []
      this.headerArray = this.fields

      for (var i = 1; i < allTextLines.length; i++) {
          if(allTextLines[i]){
              var dataArr = allTextLines[i].split(/\t|,/)
              var location = {}
              dataArr.map((data, index) =>{
                  if(this.headerArray?.[index]){
                      location[this.headerArray[index].key] = data
                  }
              })
              locations.push(location)
          }
      }
      return locations
    },
    getStatuses() {
      this.loading = true

      if (!this.scheduleId) {
        this.loading = false
        return
      }

      scheduleApi.show({ id: this.scheduleId })
        .then((response) => {
            this.status = response.data.schedule_status
            this.scheduledTime = response.data.scheduled_at
            this.message = response.data.messages
            this.loading = false
          }).catch(() => {
            this.loading = false
          })
    },
    generateData(results) {
        this.results = results
        let itemMasterIds = [];
        let data = [];
        this.results.map((r) => {
          if(!itemMasterIds.includes(r.item_master_id)){
            itemMasterIds.push(r.item_master_id);
          }
          if(r.id || r.item_id ||
              r.item_master_id || r.shelf_id || r.shelf_id || r.arrived_at ||
              r.item_code || r.item_masters_name || r.item_masters_size ||
              r.item_masters_color || r.item_masters_cost || r.item_price ||r.item_masters_retail_price){
            data.push(r)
          }
        })
        this.getAllItemMasters(itemMasterIds, data)
        this.key = Math.random()
    },
    getAllItemMasters(data, results) {
      let listStr = data.join()
      apiStockOther.getItemMasters({list_id_str: listStr}).then(response => {
        let itemMasters = response.data;
        this.tableData = this.overrideItemMaster(itemMasters, results)
      }).catch(error => {
      }).finally(() =>{
      })
    },
    overrideItemMaster(itemMasters, results){
      results.map((r, index) => {
            itemMasters.map(i => {
                if(i.id == r.item_master_id){
                    results[index].item_code = i.item_code;
                    results[index].item_masters_name = i.name;
                    results[index].item_masters_size = i.size.name;
                    results[index].item_masters_color = i.color.color_code + ':' + i.color.name;
                    results[index].item_masters_cost = i.cost;
                    results[index].item_price = r.item_price ? r.item_price  : i.price;
                    results[index].item_masters_retail_price = i.retail_price;
                }
            })
          results[index].item_masters_cost_commas = numberWithCommas(r.item_masters_cost);
          results[index].item_price_commas = numberWithCommas(r.item_price);
          results[index].item_masters_retail_price_commas = numberWithCommas(r.item_masters_retail_price);
        })
        return results;
    },
  }
}
</script>
<style>
  .statuses{
    margin-left:5px;
    margin-top:5px;
  }
</style>