var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CRow',[_c('CCol',{attrs:{"col":"12","xl":"12"}},[_c('CCard',[_c('CCardHeader',[_vm._v(" 予約詳細 ")]),_c('CRow',{staticClass:"statuses"},[_c('CCol',{staticStyle:{"font-weight":"bold"},attrs:{"sm":"2"}},[_vm._v(" ステータス ")]),_c('CCol',{attrs:{"sm":"2"}},[_vm._v(" "+_vm._s(this.status)+" ")])],1),_c('CRow',{staticClass:"statuses"},[_c('CCol',{staticStyle:{"font-weight":"bold"},attrs:{"sm":"2"}},[_vm._v(" 予約日時 ")]),_c('CCol',{attrs:{"sm":"2"}},[_vm._v(" "+_vm._s(this.scheduledTime)+" ")])],1),_c('CRow',{staticClass:"statuses"},[_c('CCol',{staticStyle:{"font-weight":"bold"},attrs:{"sm":"2"}},[_vm._v(" メッセージ ")]),_c('CCol',{attrs:{"sm":"2"}},[_vm._v(" "+_vm._s(this.message || "メッセージなし")+" ")])],1),_c('CCardBody',[(_vm.loading)?_c('div',[_c('LoadingCompoment')],1):_c('div',{key:_vm.key},[_c('CDataTable',{attrs:{"hover":"","striped":"","items":_vm.tableData,"fields":_vm.fields,"items-per-page-select":{
                label: '表示件数',
                values: [10, 20, 50, 100, 200, 500],
            },"items-per-page":200,"pagination":""},scopedSlots:_vm._u([{key:"id",fn:function(ref){
            var item = ref.item;
return _c('td',{},[_c('CButton',{staticClass:"btn-error",attrs:{"variant":"ghost"}},[_vm._v(" "+_vm._s(item.id)+" ")])],1)}},{key:"item_id",fn:function(ref){
            var item = ref.item;
return _c('td',{},[_c('CButton',{staticClass:"btn-error",attrs:{"variant":"ghost"}},[_vm._v(" "+_vm._s(item.item_id)+" ")])],1)}},{key:"arrived_at",fn:function(ref){
            var item = ref.item;
return _c('td',{},[_c('CButton',{staticClass:"btn-error",attrs:{"variant":"ghost"}},[_vm._v(" "+_vm._s(item.arrived_at)+" ")])],1)}},{key:"shop",fn:function(ref){
            var item = ref.item;
return _c('td',{},[_c('CButton',{staticClass:"btn-error",attrs:{"variant":"ghost"}},[_vm._v(" "+_vm._s(item.shop || '')+" ")])],1)}},{key:"item_master_id",fn:function(ref){
            var item = ref.item;
return _c('td',{},[_c('CButton',{staticClass:"btn-error",attrs:{"variant":"ghost"}},[_vm._v(" "+_vm._s(item.item_master_id || '')+" ")])],1)}},{key:"shelf_id",fn:function(ref){
            var item = ref.item;
return _c('td',{},[_c('CButton',{staticClass:"btn-error",attrs:{"variant":"ghost"}},[_vm._v(" "+_vm._s(item.shelf_id || '')+" ")])],1)}},{key:"item_masters_size",fn:function(ref){
            var item = ref.item;
return _c('td',{},[_c('CButton',{staticClass:"btn-error",attrs:{"variant":"ghost"}},[_vm._v(" "+_vm._s(item.item_masters_size || '')+" ")])],1)}},{key:"item_masters_color",fn:function(ref){
            var item = ref.item;
return _c('td',{},[_c('CButton',{staticClass:"btn-error",attrs:{"variant":"ghost"}},[_vm._v(" "+_vm._s(item.item_masters_color || '')+" ")])],1)}},{key:"item_code",fn:function(ref){
            var item = ref.item;
return _c('td',{},[_c('CButton',{staticClass:"btn-error",attrs:{"variant":"ghost"}},[_vm._v(" "+_vm._s(item.item_code || '')+" ")])],1)}},{key:"item_masters_name",fn:function(ref){
            var item = ref.item;
return _c('td',{},[_c('CButton',{staticClass:"btn-error",attrs:{"variant":"ghost"}},[_vm._v(" "+_vm._s(item.item_masters_name || '')+" ")])],1)}},{key:"item_masters_cost",fn:function(ref){
            var item = ref.item;
return _c('td',{},[_c('CButton',{staticClass:"btn-error",attrs:{"variant":"ghost"}},[_vm._v(" "+_vm._s(item.item_masters_cost_commas || '')+" ")])],1)}},{key:"item_price",fn:function(ref){
            var item = ref.item;
return _c('td',{},[_c('CButton',{staticClass:"btn-error",attrs:{"variant":"ghost"}},[_vm._v(" "+_vm._s(item.item_price_commas || '')+" ")])],1)}},{key:"item_masters_retail_price",fn:function(ref){
            var item = ref.item;
return _c('td',{},[_c('CButton',{staticClass:"btn-error",attrs:{"variant":"ghost"}},[_vm._v(" "+_vm._s(item.item_masters_retail_price_commas || '')+" ")])],1)}},{key:"pp_bs_code",fn:function(ref){
            var item = ref.item;
return _c('td',{},[_c('CButton',{staticClass:"btn-error",attrs:{"variant":"ghost"}},[_vm._v(" "+_vm._s(item.pp_bs_code || '')+" ")])],1)}},{key:"is_special",fn:function(ref){
            var item = ref.item;
return _c('td',{},[_c('CButton',{staticClass:"btn-error",attrs:{"variant":"ghost"}},[_vm._v(" "+_vm._s(item.is_special || '')+" ")])],1)}},{key:"status",fn:function(ref){
            var item = ref.item;
return _c('td',{},[_c('CButton',{staticClass:"btn-error",attrs:{"variant":"ghost"}},[_vm._v(" "+_vm._s(item.status || '')+" ")])],1)}}])})],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }